import { useCallback, useEffect, useState } from 'react';
import { CourseDTO } from '../model/CourseDTO';
import { AxiosError } from 'axios';
import fetchFromBackend from '../api/fetchFromBackend';

export default function useCourseById(id: string) {
  const [course, setCourse] = useState<CourseDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<CourseDTO>(`v2/event/${id}`)
      .then((res) => {
        setCourse(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  return { course, loading, fetchError: error };
}

import React from 'react';
import styles from './TrafficLight.module.css';
import { Tooltip } from '@mui/material';

const TrafficLight = ({
  status,
  color,
  reservationAllowed
}: {
  status: string;
  color: string;
  reservationAllowed: boolean;
}) => {
  return (
    <Tooltip
      title={<h1 style={{ textAlign: 'center', marginBottom: '0.2em' }}>{status}</h1>}
      placement="right"
      arrow
      enterTouchDelay={50}>
      <div
        className={`${styles.trafficLight} ${color === 'green' && !reservationAllowed ? styles.trafficLightAusgebucht : ''}`}>
        <div className={`${styles.light} ${color === 'red' ? styles.red : ''}`}></div>
        <div className={`${styles.light} ${color === '#ffcc00' ? styles.yellow : ''}`}></div>
        <div
          className={`${styles.light} ${color === 'green' || status === 'ausgebucht' ? styles.green : ''}`}></div>
      </div>
    </Tooltip>
  );
};

export default TrafficLight;

import { useEffect, useState } from 'react';
import removeDuplicate from '../../../util/removeDuplicate';
import { toLocalDateWithDayString } from '../../../util/toLocalDateString';
import styles from '../../DatesOverview/Details/TanzabendDetails.module.css';
import { AllCoursesDTO, CourseDTO } from '../../../model/CourseDTO';

type TanzabendDashboardProps = {
  tanzabende: AllCoursesDTO;
};
export default function TanzabendDashboard({ tanzabende }: TanzabendDashboardProps) {
  const [displayedItems, setDisplayedItems] = useState<CourseDTO[]>([]);

  useEffect(() => {
    if (tanzabende) {
      setDisplayedItems([]);
      removeDuplicate<CourseDTO>(tanzabende.allCourses, (a, b) => a.id === b.id).forEach(
        (item, index) => {
          setTimeout(() => {
            setDisplayedItems((currentItems) => [...currentItems, item]);
          }, 300 * index); // 100ms Verzögerung für jedes Element
        }
      );
    }
  }, [tanzabende]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
      }}>
      <div style={{ paddingLeft: '10em', paddingRight: '10em', paddingTop: '6em' }}>
        <h1 style={{ color: '#fff' }}>
          Die nächsten <span style={{ color: '#fc0' }}>Tanzabende</span>:
        </h1>
        {removeDuplicate<CourseDTO>(displayedItems, (a, b) => a.id === b.id)
          .slice(0, 6)
          .map((item) => {
            return (
              <div className={'fadeIn'} style={{ padding: '0.5em' }}>
                <div
                  style={{
                    backgroundColor: '#aaaaaaaa',
                    borderRadius: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    color: 'white'
                  }}>
                  <div style={{ width: '20%' }}>
                    <h2>{item.title}: </h2>
                  </div>
                  <h2>{toLocalDateWithDayString(item.startDate)}</h2>
                  <h2> - </h2>
                  <h2 className={styles.detailTime}>{item.startTime}</h2>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

import CoursePage from './CoursePage';
import useEvents from '../../hooks/useEvents';

export default function RefreshsPage() {
  const { events, loading } = useEvents('refresh');
  return (
    <CoursePage
      courses={events}
      loading={loading}
      pageTitle={'Refreshtanzen'}
      category={'refresh'}
    />
  );
}

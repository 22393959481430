import styles from './CoursePaper.module.css';
import { CourseDTO } from '../../model/CourseDTO';
import { Button, Paper } from '@mui/material';
import TrafficLight from '../../component/TrafficLight';
import TodayIcon from '@mui/icons-material/Today';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { toLocalDateWithDayString } from '../../util/toLocalDateString';
import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CategoryType } from '../intern/coursePage/edit/EditCoursePage';

type CoursePaperProps = {
  course: CourseDTO;
  category: CategoryType;
};

export default function CoursePaper({ course, category }: CoursePaperProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Paper variant={'outlined'} className={styles.paper}>
        <div className={styles.contentContainer}>
          <div className={styles.trafficLightContainer}>
            {category !== 'tanzabend' ? (
              <TrafficLight
                status={course.statusText}
                color={course.color}
                reservationAllowed={course.reservationAllowed}
              />
            ) : (
              <div className={styles.placeholder} />
            )}
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.noSpacingContainer}>
              <h1>{course.title}</h1>
              <p>{course.topic}</p>
            </div>
            <div className={styles.timeAndDateContainer}>
              <div className={styles.timeContainer}>
                <TodayIcon className={styles.timeIcon} />
                <h2 style={{ color: '#ffffff' }}>{toLocalDateWithDayString(course.startDate)}</h2>
              </div>
              <div className={styles.timeContainer}>
                <ScheduleIcon className={styles.timeIcon} />
                <h2 style={{ color: '#ffffff' }}>{course.startTime}</h2>
              </div>
            </div>
            {category === 'newsfeed' ? null : (
              <div className={styles.buttonSection}>
                <Button
                  className={styles.registerButton}
                  disabled={!course.reservationAllowed}
                  onClick={() => navigate(`/reserve/${course.id}`)}>
                  Zur Reservierung <LoginIcon />
                </Button>
                <Button className={styles.registerButton} onClick={toggleExpand}>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Paper>
      {isExpanded && (
        <div className={`${styles.coursePaperDetails} ${isExpanded ? styles.expanded : ''}`}>
          {course.details !== '' && course.details !== undefined ? (
            <div>
              <p>{course.statusText}</p>
              <h3>Weitere Informationen</h3>
              <p>{course.details}</p>
            </div>
          ) : (
            <div>
              <p>{course.statusText}</p>
              <h3>Keine weiteren Informationen vorhanden</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

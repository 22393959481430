import React, { useState } from 'react';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from '../../../registration/FormTheme';
import { Box, Button, IconButton, TextField } from '@mui/material';
import styles from './Newnewsform.module.css';
import { useAuth } from '../../../../context/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LoginPage from '../../../intern/Login/LoginPage';

const NewNewsForm = () => {
  const [file, setFile] = useState<File | null>(null);
  const [headline, setHeadline] = useState('');
  const [subHeadline, setSubHeadline] = useState('');
  const [details, setDetails] = useState('');

  const { isLoggedIn, logout } = useAuth();

  const navigate = useNavigate();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const isFormValid = () => {
    return !(!headline || !subHeadline || !details || !file);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isFormValid() && file) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('headline', headline);
      formData.append('subheading', subHeadline);
      formData.append('detailsText', details);

      try {
        await axios.post('https:///api.ettlingen-tanzt.de/news', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setFile(null);
        setHeadline('');
        setSubHeadline('');
        setDetails('');
      } catch (error) {
        alert('Da hat etwas nicht geklappt, bitte versuche es erneut!');
      }
    } else {
      alert('Bitte fülle alle Felder aus und wähle eine Datei aus.');
    }
  };

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return (
    <ThemeProvider theme={formTheme}>
      <div className={styles.newnewsFormContainer}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginBottom: '-5em',
            padding: '2em'
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1em' }}>
            <IconButton
              onClick={() => navigate('/newsfeedoverview')}
              style={{ backgroundColor: '#fc0' }}>
              <ArrowBack style={{ color: 'white' }} />
            </IconButton>
            <h1>New News anlegen</h1>
            <IconButton onClick={logout} style={{ backgroundColor: '#fc0' }}>
              <LogoutIcon style={{ color: 'white' }} />
            </IconButton>
          </div>
          <TextField
            className={styles.formInput}
            margin="normal"
            required
            fullWidth
            variant={'standard'}
            label="Headline"
            autoFocus
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
          />

          <TextField
            className={styles.formInput}
            margin="normal"
            required
            fullWidth
            variant={'standard'}
            label="Subheadline"
            value={subHeadline}
            onChange={(e) => setSubHeadline(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            variant={'standard'}
            type="text"
            multiline
            label="Details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span" fullWidth sx={{ mt: 2, mb: 2 }}>
              Bild Hochladen
            </Button>
          </label>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isFormValid()}
            sx={{ mt: 3, mb: 2 }}>
            Absenden
          </Button>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default NewNewsForm;

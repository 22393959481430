import { useCallback, useEffect, useState } from 'react';
import fetchFromBackend from '../api/fetchFromBackend';
import { AxiosError } from 'axios';
import { AllCoursesDTO } from '../model/CourseDTO';
import { CategoryType } from '../features/intern/coursePage/edit/EditCoursePage';

export default function useEvents(type: CategoryType) {
  const [events, setEvents] = useState<AllCoursesDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);

  const path = `v2/events/${type}`;

  const load = useCallback(() => {
    setLoading(true);
    setEvents(null);
    fetchFromBackend<AllCoursesDTO>(path)
      .then(setEvents)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path]);

  useEffect(() => load(), [load]);

  return { events, loading, error, reload: load };
}

import CoursePage from './CoursePage';
import useEvents from '../../hooks/useEvents';

export default function SpecialCoursesPage() {
  const { events, loading } = useEvents('special');
  return (
    <CoursePage
      courses={events}
      loading={loading}
      pageTitle={'TCE-Sonderkurse'}
      category={'special'}
    />
  );
}

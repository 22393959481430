import styles from './DatesOverview.module.css';
import CustomCircularProgress from '../../util/customLoadingSpinner';
import useFerien from '../../hooks/useFerien';

import FerienDetails from './Details/FerienDetails';
import { FerienDTO } from '../../model/FerienDTO';
import ErrorPage, { ERROR_CODE_DATES_OVERVIEW_FERIEN } from '../../error/ErrorPage';
import { useEffect, useState } from 'react';
import removeDuplicate from '../../util/removeDuplicate';

export default function DatesOverview() {
  const { ferien, error, loading } = useFerien();

  const [displayedFerien, setDisplayedFerien] = useState<FerienDTO[]>([]);
  useEffect(() => {
    if (ferien) {
      setDisplayedFerien([]);
      ferien.allHoliday.forEach((item, index) => {
        setTimeout(() => {
          setDisplayedFerien((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [ferien]);

  if (error) return <ErrorPage location={ERROR_CODE_DATES_OVERVIEW_FERIEN} error={error} />;

  if (loading || ferien === null) {
    return <CustomCircularProgress />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.rightSide}>
        <div className={styles.rightUpperSide}>
          <div className={styles.termineHeadline}>
            <h1>
              <span className={'grayText'}>Wir machen </span>
              <span className={styles.priceYellow}>Ferien</span>
              <span className={'whiteText'}>.</span>
            </h1>
          </div>
          <p>An den folgenden Terminen hat unsere Tanzschule geschlossen</p>
          <p>In diesen Zeiten findet kein regulärer Tanzunterricht in den Paarkursen statt. </p>
          <p>
            Die Ferienzeiten unserer Kinder- und Jugenkurse richten sich nach den Schulferien in
            Baden-Württemberg{' '}
          </p>
          <p>Unser Büro ist nicht besetzt, die Beantwortung von Mails kann sich verzögern.</p>
        </div>

        <div className={styles.termineListContainer}>
          <ul className={styles.list}>
            {removeDuplicate<FerienDTO>(displayedFerien, (a, b) => a.id === b.id).map((ferien) => (
              <div className={'fadeIn'}>
                <FerienDetails ferien={ferien} />
              </div>
            ))}
            {displayedFerien.length === 0 ? <h2>Aktuell sind keine Ferien geplant</h2> : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

import InternCoursePage from '../InternCoursePage';
import useInternCourses from '../../../../hooks/useInternCourses';

export default function InternTCEEventsPage() {
  const { courses, loading, error, reload } = useInternCourses('tceevent');
  return (
    <InternCoursePage
      courses={courses}
      loading={loading}
      error={error}
      pageTitle={'TCE-Events'}
      category={'tceevent'}
      reload={reload}
    />
  );
}

import InternCoursePage from '../InternCoursePage';
import useInternCourses from '../../../../hooks/useInternCourses';

export default function InternWorkshopsPage() {
  const { courses, loading, error, reload } = useInternCourses('workshop');
  return (
    <InternCoursePage
      courses={courses}
      loading={loading}
      error={error}
      pageTitle={'Workshops'}
      category={'workshop'}
      reload={reload}
    />
  );
}

import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import sendDelete from '../api/sendDelete';
import { useAuth } from '../context/AuthContext';

export default function useDeleteNews() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const { getHeader, logout } = useAuth();

  const deleteNews = useCallback(
    (id: string, load: () => unknown) => {
      const header = getHeader();
      setLoading(true);
      sendDelete('intern/news', id, header)
        .then(load)
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            logout();
          } else {
            setError(err);
          }
        })
        .finally(() => setLoading(false));
    },
    [getHeader, logout]
  );

  return { loading, error, deleteNews };
}

import Box from '@mui/material/Box';
import { Divider, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import styles from './PremiumContainer.module.css';
import EmailButton from './EmailButton';

const tabSx = {
  '& .MuiTabs-indicator': {
    backgroundColor: '#fc0'
  },
  '& .MuiButtonBase-root.MuiTab-root': {
    color: '#000',
    '&.Mui-selected': {
      color: '#ffcc00'
    }
  }
};

export default function PremiumContainer() {
  const [value, setValue] = useState(0);

  const getMonths = (index: number): number => {
    if (index === 0) return 9;
    if (index === 1) return 6;
    return 1;
  };
  const getPrice = (index: number): string => {
    if (index === 0) return '18,90';
    if (index === 1) return '21,90';
    return '23,90';
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box className={styles.container}>
      <div className={styles.content}>
        <h1>Premium-Mitglied</h1>
        <Tabs value={value} onChange={handleChange} sx={tabSx}>
          <Tab className={styles.tabs} label={'Stufe 1'} />
          <Tab className={styles.tabs} label={'Stufe 2'} />
          <Tab className={styles.tabs} label={'Stufe 3'} />
        </Tabs>

        <ul className={styles.ul}>
          <li>
            Laufzeit:{' '}
            <span className={styles.important}>
              {getMonths(value)} {value === 2 ? 'Monat' : 'Monate'}
            </span>
          </li>
          <li>
            Preis: <span className={styles.important}>€ {getPrice(value)}</span>
          </li>
          <li>wöchentliche Abbuchung</li>
          <li> Preis pro Person</li>
          <li>
            {' '}
            keine Abbuchung in den{' '}
            <a
              href={'https://tcevent.tanzcentrum-ettlingen.de/dates'}
              target={'_blank'}
              rel="noreferrer">
              Tanzschulferien
            </a>
          </li>
          <li> monatlich kündbar nach der Grundlaufzeit</li>
          <li>
            Preis bei monatlicher Laufzeit: <span className={styles.important}>€23,90</span>
          </li>
          <Divider style={{ backgroundColor: '#fff', width: '100%' }} />
          <li>Mehrfach / Woche zum Tanzen kommen möglich</li>
          <li>
            kostenfreie Teilnahme an allen{' '}
            <a
              href={'https://tcevent.tanzcentrum-ettlingen.de/courses'}
              target={'_blank'}
              rel="noreferrer">
              TCE-Sonderkursen
            </a>
          </li>
          <li>Linedance inklusive</li>
        </ul>
      </div>

      <EmailButton membershipType={'Premium'} />
    </Box>
  );
}

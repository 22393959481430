import { useCallback, useEffect, useState } from 'react';
import { CourseDTO } from '../model/CourseDTO';
import { AxiosError } from 'axios';
import { useAuth } from '../context/AuthContext';
import fetchAuthenticatedFromBackend from '../api/fetchAuthenticatedFromBackend';

export default function useInternCourseById(id: string) {
  const [course, setCourse] = useState<CourseDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const { getHeader } = useAuth();

  const load = useCallback(() => {
    const header = getHeader();
    setLoading(true);
    fetchAuthenticatedFromBackend<CourseDTO>(`intern/v2/event/${id}`, header)
      .then((res) => {
        setCourse(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [getHeader, id]);
  useEffect(() => {
    if (id !== '0') load();
  }, [id, load]);

  return { course, loading, fetchError: error };
}

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import styles from './ReservationsPage.module.css';
import ReservationPaper from './Paper/ReservationPaper';
import useReservations from '../../../hooks/useReservations';
import { ReservationsDTO } from '../../../model/ReservationsDTO';
import removeDuplicate from '../../../util/removeDuplicate';
import ErrorPage, {
  ERROR_CODE_RESERVATION_PAGE_DELETE,
  ERROR_CODE_RESERVATION_PAGE_FETCH
} from '../../../error/ErrorPage';
import CustomCircularProgress from '../../../util/customLoadingSpinner';
import toLocalDateString from '../../../util/toLocalDateString';
import useCourseById from '../../../hooks/useCourseById';
import { useNavigate, useParams } from 'react-router-dom';

export default function ReservationsPage() {
  const { id } = useParams();
  const { reservations, loading, fetchError, deleteError, deleteReservation } = useReservations(
    id!
  );
  const { course, loading: isLoading } = useCourseById(id!);
  const navigate = useNavigate();
  const [displayedItems, setDisplayedItems] = useState<ReservationsDTO[]>([]);

  useEffect(() => {
    if (reservations) {
      setDisplayedItems([]);
      removeDuplicate<ReservationsDTO>(reservations, (a, b) => a.id === b.id).forEach(
        (item, index) => {
          setTimeout(() => {
            setDisplayedItems((currentItems) => [...currentItems, item]);
          }, 50 * index);
        }
      );
    }
  }, [reservations]);

  const deleteFunction = async (id: string) => {
    setDisplayedItems([]);
    await deleteReservation(id);
  };

  if (fetchError) {
    return <ErrorPage location={ERROR_CODE_RESERVATION_PAGE_FETCH} error={fetchError} />;
  }
  if (deleteError) {
    return <ErrorPage location={ERROR_CODE_RESERVATION_PAGE_DELETE} error={deleteError} />;
  }
  if (loading || isLoading) {
    return <CustomCircularProgress />;
  }
  return (
    <div className={styles.paperContainer}>
      {course ? (
        <h1>
          Reservierungen für: {course.title} am {toLocalDateString(course.startDate)} um{' '}
          {course.startTime} Uhr ({(reservations ?? []).length})
        </h1>
      ) : null}
      <Button className={styles.backButton} onClick={() => navigate(-1)}>
        Zurück
      </Button>
      {removeDuplicate<ReservationsDTO>(displayedItems, (a, b) => a.id === b.id).map(
        (item, index) => (
          <div key={index} className={'fadeIn'}>
            <ReservationPaper deleteFunction={deleteFunction} reservation={item} />
          </div>
        )
      )}
      {displayedItems.length === 0 ? (
        <div>
          <h1>Aktuell gibt es keine Reservierungen für dieses Event</h1>
        </div>
      ) : null}
    </div>
  );
}

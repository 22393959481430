import AppLogo from '../../assets/tcevent_logo.png';
import Appstore from '../../assets/DownloadOnAppStore.png';
import GooglePlay from '../../assets/DownloadOnGooglePlay.png';
import styles from './getApp.module.css';

const openAppleLink = () => {
  window.open('https://apps.apple.com/de/app/tcevent/id6475267927', '_blank');
};

const openGoogleLink = () => {
  window.open(
    'https://play.google.com/store/apps/details?id=de.geiselhart.tcevent&gl=DE',
    '_blank'
  );
};

export default function getApp() {
  return (
    <div className={styles.container}>
      <h1>Die tcevent-App</h1>
      <img src={AppLogo} className={styles.applogo} alt="tceventLogo" />
      <div className={styles.linkContainer}>
        <img
          src={Appstore}
          onClick={openAppleLink}
          className={styles.link}
          alt="download on AppStore"
        />
        <img
          src={GooglePlay}
          onClick={openGoogleLink}
          className={styles.link}
          alt="download von Google Playstore"
        />
      </div>
    </div>
  );
}

import styles from './InternDatesOverview.module.css';
import CustomCircularProgress from '../../../util/customLoadingSpinner';

import InternFerienDetails from './Details/InternFerienDetails';
import { FerienDTO } from '../../../model/FerienDTO';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ErrorPage, { ERROR_CODE_INTERN_DATES_OVERVIEW_FERIEN } from '../../../error/ErrorPage';
import removeDuplicate from '../../../util/removeDuplicate';
import useInternFerien from '../../../hooks/useInternFerien';
import { useNavigate } from 'react-router-dom';

export default function InternDatesOverview() {
  const navigate = useNavigate();

  const { ferien, error, loading, reloadFerien } = useInternFerien();

  const [displayedFerien, setDisplayedFerien] = useState<FerienDTO[]>([]);
  useEffect(() => {
    setDisplayedFerien([]);
    if (ferien) {
      ferien.allHoliday.forEach((item, index) => {
        setTimeout(() => {
          setDisplayedFerien((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [ferien]);

  useEffect(() => {
    reloadFerien();
  }, [reloadFerien]);
  if (error) return <ErrorPage location={ERROR_CODE_INTERN_DATES_OVERVIEW_FERIEN} error={error} />;

  if (loading || ferien === null) {
    return <CustomCircularProgress />;
  }
  return (
    <div className={styles.page}>
      <div className={styles.rightSide}>
        <div className={styles.rightUpperSide}>
          <div className={styles.headingContainer}>
            <h1>
              <span className={'grayText'}>Wir machen </span>
              <span className={styles.priceYellow}>Ferien</span>
              <span className={'whiteText'}>.</span>
            </h1>
            <IconButton
              className={styles.iconButtons}
              onClick={() => navigate('/intern/newHoliday')}>
              <AddIcon />
            </IconButton>
          </div>
          <p>An den folgenden Terminen hat unsere Tanzschule geschlossen</p>
          <p>In diesen Zeiten findet kein regulärer Tanzunterricht statt. </p>
          <p>Unser Büro ist nicht besetzt, die Beantwortung von Mails kann sich verzögern.</p>
        </div>

        <div className={styles.termineListContainer}>
          <ul className="termineListe">
            {removeDuplicate<FerienDTO>(displayedFerien, (a, b) => a.id === b.id).map((ferien) => (
              <div className={'fadeIn'}>
                <InternFerienDetails ferien={ferien} navigate={navigate} />
              </div>
            ))}
            {displayedFerien.length === 0 ? <h2>Aktuell sind keine Ferien geplant</h2> : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  CalendarMonth,
  Celebration,
  EmojiEvents,
  HomeRepairService,
  Info,
  Lightbulb,
  Logout,
  Nightlife,
  People,
  Refresh,
  Search
} from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';

const drawerItems = [
  { key: 'dates', label: 'Ferien', icon: <CalendarMonth />, path: '/intern/dates' },
  { key: 'tanzabend', label: 'Tanzabende', icon: <Nightlife />, path: '/intern/tanzabende' },
  { key: 'clubfinder', label: 'Clubfinder', icon: <People />, path: '/intern/clubfinder' },
  { key: 'specialCourses', label: 'TCE-Kurse', icon: <Search />, path: '/intern/specialCourses' },
  { key: 'refresh', label: 'Refreshtanzen', icon: <Refresh />, path: '/intern/refresh' },
  { key: 'tceevents', label: 'Events', icon: <EmojiEvents />, path: '/intern/tceevents' },
  { key: 'workshops', label: 'Workshops', icon: <HomeRepairService />, path: '/intern/workshops' },
  { key: 'youthDates', label: 'Jugend', icon: <Celebration />, path: '/intern/youthDates' },
  {
    key: 'membershipInfos',
    label: 'Mitgliedschaft',
    icon: <Lightbulb />,
    path: '/intern/membershipInfos'
  },
  { key: 'about', label: 'Über tcevent', icon: <Info />, path: '/intern/about' }
];

export default function DrawerItems({
  navigateTo,
  closeDrawer
}: {
  navigateTo: (path: string) => void;
  closeDrawer: () => void;
}) {
  const { logout } = useAuth();

  return (
    <div>
      {drawerItems.map(({ key, label, icon, path }) => (
        <React.Fragment key={key}>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigateTo(path);
                closeDrawer();
              }}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        </React.Fragment>
      ))}
      <Divider />
      <ListItem disablePadding>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from '../../../registration/FormTheme';
import CustomCircularProgress from '../../../../util/customLoadingSpinner';
import removeEmojis from '../../../../util/removeEmojis';
import ErrorPage, {
  ERROR_CODE_EDIT_COURSE_PAGE_FETCH,
  ERROR_CODE_EDIT_COURSE_PAGE_SEND
} from '../../../../error/ErrorPage';
import { RequestType } from '../../../../api/sendToBackend';
import usePostInternData from '../../../../hooks/usePostInternData';
import { useNavigate, useParams } from 'react-router-dom';
import useInternCourseById from '../../../../hooks/useInternCourseById';

export type CategoryType =
  | 'refresh'
  | 'workshop'
  | 'special'
  | 'tceevent'
  | 'newsfeed'
  | 'youthparty'
  | 'sonstiges'
  | 'tanzabend';

export type DisplayCategoryType =
  | 'Refresh'
  | 'Workshop'
  | 'TCEKurs'
  | 'Event'
  | 'Jugendparty'
  | 'Tanzabend';

export default function EditCoursePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { course, loading, fetchError } = useInternCourseById(id ?? '0');
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [category, setCategory] = useState('');
  const [minNoOfPart, setMinNoOfPart] = useState<number>(5);
  const [details, setDetails] = useState('');
  const [fullyBooked, setFullyBooked] = useState(false);
  const [inPairs, setInPairs] = useState(true);
  const [registrationDeadlineDate, setRegistrationDeadlineDate] = useState('');
  const [registrationDeadlineTime, setRegistrationDeadlineTime] = useState('');
  const [customRegistrationDeadline, setCustomRegistrationDeadline] = useState(false);

  const categories: CategoryType[] = [
    'refresh',
    'workshop',
    'special',
    'tceevent',
    'youthparty',
    'tanzabend'
  ];
  useEffect(() => {
    if (!loading && course && id !== '0' && course.id) {
      setTitle(course.title);
      setTopic(course.topic);
      setStartDate(course.startDate);
      setStartTime(course.startTime);
      setCategory(course.category);
      setMinNoOfPart(course.minNoOfParticipants);
      setDetails(course.details);
      setFullyBooked(course.fullyBooked);
      setInPairs(course.inPairs);
      setRegistrationDeadlineDate(course.registrationDeadline.split('T')[0]);
      setRegistrationDeadlineTime(
        new Date(course.registrationDeadline).toLocaleTimeString('de-DE', {
          timeZone: 'Europe/Berlin'
        })
      );
    }
  }, [loading, course, id]);
  const path: string = !id ? 'intern/v2/event' : `intern/v2/event`;
  const method: RequestType = !id ? 'post' : 'patch';
  const { isLoading, error, postData } = usePostInternData(path, method);
  const isFormValid = () => {
    return !(!title || !topic || !startTime || !startDate || !category);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const deadlineTime = registrationDeadlineTime.split(':');
    const deadline = new Date(registrationDeadlineDate);
    deadline.setHours(Number(deadlineTime[0]), Number(deadlineTime[1]));
    const data = {
      id: removeEmojis(id ?? '0'),
      title: removeEmojis(title),
      topic: removeEmojis(topic),
      startDate: removeEmojis(startDate),
      startTime: removeEmojis(startTime),
      minNoOfParticipants: minNoOfPart,
      category: removeEmojis(category),
      details: removeEmojis(details),
      fullyBooked: fullyBooked,
      inPairs: inPairs,
      registrationDeadline:
        customRegistrationDeadline && course?.registrationDeadline !== deadline.toISOString()
          ? deadline.toISOString()
          : null
    };
    postData(data, () => navigate(-1));
  };

  const getDisplayCategory = (cat: CategoryType): DisplayCategoryType => {
    if (cat === 'refresh') return 'Refresh';
    if (cat === 'special') return 'TCEKurs';
    if (cat === 'youthparty') return 'Jugendparty';
    if (cat === 'workshop') return 'Workshop';
    if (cat === 'tanzabend') return 'Tanzabend';
    return 'Event';
  };

  if (fetchError) {
    return <ErrorPage location={ERROR_CODE_EDIT_COURSE_PAGE_FETCH} error={fetchError} />;
  }
  if (error) {
    return <ErrorPage location={ERROR_CODE_EDIT_COURSE_PAGE_SEND} error={error} />;
  }
  console.log(isLoading, loading);
  if (isLoading || loading) {
    return <CustomCircularProgress />;
  }
  return (
    <ThemeProvider theme={formTheme}>
      <div style={{ paddingBottom: '10em' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginInline: '5em',
            marginTop: '5em',
            padding: '2em'
          }}>
          <div>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Titel"
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Thema"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            />
          </div>
          <div>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startdatum"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startzeit"
              type="time"
              InputLabelProps={{ shrink: true }}
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <div>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Benutzerdefinierter Reservierungsschluss"
                checked={customRegistrationDeadline}
                onChange={() => {
                  setCustomRegistrationDeadline(!customRegistrationDeadline);
                }}
              />
              {customRegistrationDeadline && (
                <div>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant={'standard'}
                    label="Reservierungsdeadline (Datum)"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={registrationDeadlineDate}
                    onChange={(e) => {
                      setRegistrationDeadlineDate(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    variant={'standard'}
                    label="Reservierungsdeadline (Zeit)"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    value={registrationDeadlineTime}
                    onChange={(e) => {
                      setRegistrationDeadlineTime(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Kategorie</InputLabel>
              <Select
                labelId="category-label"
                id="category-select"
                value={category}
                label="Kategorie"
                onChange={(e) => setCategory(e.target.value as CategoryType)}>
                {categories.map((cat) => (
                  <MenuItem sx={{ color: 'black' }} key={cat} value={cat}>
                    {getDisplayCategory(cat)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required={false}
              fullWidth
              variant={'standard'}
              label="Mindestteilnehmeranzahl"
              type="number"
              inputProps={{ min: 0 }}
              value={`${minNoOfPart}`}
              onChange={(e) => {
                if (e.target.value === '') {
                  setMinNoOfPart(0);
                } else {
                  setMinNoOfPart(Number.parseInt(e.target.value));
                }
              }}
            />
          </div>
          <div>
            <TextField
              margin="normal"
              required={false}
              fullWidth
              variant={'standard'}
              label="Details"
              type="text"
              multiline
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </div>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Ausgebucht"
            checked={fullyBooked}
            onChange={() => setFullyBooked(!fullyBooked)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Anmeldung NICHT Paarweise"
            checked={!inPairs}
            onChange={() => setInPairs(!inPairs)}
          />
          <div>
            <Button
              type={'button'}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => navigate(-1)}>
              Abbrechen
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
              sx={{ mt: 3, mb: 2 }}>
              Absenden
            </Button>
          </div>
        </Box>
      </div>
    </ThemeProvider>
  );
}

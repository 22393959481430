import CoursePage from './CoursePage';
import useEvents from '../../hooks/useEvents';

export default function YouthpartyPage() {
  const { events, loading } = useEvents('youthparty');

  return (
    <CoursePage courses={events} loading={loading} pageTitle={'Jugend'} category={'youthparty'} />
  );
}
